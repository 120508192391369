.sg-language-switcher {
  position: fixed;
  top: 88px;
  right: 0;
  /*bottom: 0;
  left: 20px;*/
  background: rgba(0,0,0,0.5);
  padding: 5px 10px;
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
}