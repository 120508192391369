.sg-item-card {
  display: flex;

  .sg-image-wrapper {
    flex-basis: 35%;
    padding-bottom: 35%;
    position: relative;
    width: 35%;
    background-color: #cccccc;
    height: 100%;
    padding: 0;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .sg-copy-wrapper {
    background: white;
    display: flex;
    flex-basis: 65%;
    flex-flow: row wrap;
    padding: 16px 14px;
    width: 65%;
    justify-content: space-between;

    .sg-title {
      flex-basis: 70%;
      width: 70%;
    }

    .sg-price {
      flex-basis: 20%;
      text-align: right;
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .sg-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
      align-items: flex-end;
    }


    a, button {
      align-self: flex-end;
      text-overflow: ellipsis;
      max-width: 100%;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      padding-left: 8px;
      padding-right: 8px;
      flex-shrink: 1;
      // flex-basis: 35%;

      & > span {
        display: contents;
      }

      &+a, &+button {
        margin-left: 8px;
      }
    }
  }
}
