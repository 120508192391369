.sg-tooltips {
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  flex: 2 1 35%;

  .sg-tooltip {
    background: white;
    border-radius: 0;
    font-size: 14px;
    // height: 40px;
    // margin-right: 8px;

    &:hover,
    &:focus,
    &:active { background: white; }

    // @media only screen and (max-width: 768px) {
    //   margin-right: 4px;
    // }
  }
}