.sg-drawer {
  position: fixed;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 400px;
  transform: translate3d(400px, 0, 0);
  will-change: transform;
  transition: transform .125s ease;
  display: block;
  webkit-backface-visibility: hidden;

  &.open {
    transform: translate3d(0, 0, 0);

    & + .sg-overlay { display: block; }
  }
}

.sg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 99998;
  display: none;
}
