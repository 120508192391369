$header-height: 64px;

.sg-content-outer {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  padding-top: $header-height;
  min-height: calc(100vh - 64px); // Can't use calc() with vars.
  overflow-x: hidden;
  //overflow-y: scroll;
  padding-bottom: $header-height;

  &.top { align-items: flex-start; }
}

.sg-content-inner {
  width: 95%;
  max-width: 1200px;
}

@media only screen and (max-width: 768px) {
  .sg-content-outer { align-items: flex-start; }
  .sg-content-inner { width: 100%; }
}

@media only screen and (max-height: 500px) {
  .sg-content-outer { align-items: flex-start; }
}
