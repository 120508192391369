@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Gothic+A1:300,400,500,700);
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}*,*:before,*:after{box-sizing:border-box;-webkit-overflow-scrolling:touch}#root{width:100%;overflow:hidden;min-height:100vh}body{font-family:"Gothic A1",sans-serif;overflow-x:hidden;background-color:#f2f2f2}img{width:100%;height:auto;display:block}u{cursor:pointer}a{text-decoration:none}i[class*=fa]{line-height:1.2}.clearfix:after{display:block;content:"";clear:both}.ellipsis{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%;display:block}.divider{width:100%;height:1px;display:block;background-color:rgba(0,0,0,.4)}input:disabled{pointer-events:none !important}.react-datepicker-wrapper,.react-datepicker__input-container{width:100%}
.sg-drawer{position:fixed;background-color:#fff;height:100vh;top:0;right:0;z-index:99999;width:400px;-webkit-transform:translate3d(400px, 0, 0);transform:translate3d(400px, 0, 0);will-change:transform;transition:-webkit-transform .125s ease;transition:transform .125s ease;transition:transform .125s ease, -webkit-transform .125s ease;display:block;webkit-backface-visibility:hidden}.sg-drawer.open{-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0)}.sg-drawer.open+.sg-overlay{display:block}.sg-overlay{position:fixed;top:0;left:0;width:100%;height:100vh;background-color:rgba(0,0,0,.5);z-index:99998;display:none}
.sg-language-switcher {
  position: fixed;
  top: 88px;
  right: 0;
  /*bottom: 0;
  left: 20px;*/
  background: rgba(0,0,0,0.5);
  padding: 5px 10px;
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
}
.sg-content-outer{-webkit-align-items:center;align-items:center;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;max-width:100vw;padding-top:64px;min-height:calc(100vh - 64px);overflow-x:hidden;padding-bottom:64px}.sg-content-outer.top{-webkit-align-items:flex-start;align-items:flex-start}.sg-content-inner{width:95%;max-width:1200px}@media only screen and (max-width: 768px){.sg-content-outer{-webkit-align-items:flex-start;align-items:flex-start}.sg-content-inner{width:100%}}@media only screen and (max-height: 500px){.sg-content-outer{-webkit-align-items:flex-start;align-items:flex-start}}
.sg-page-title{padding:20px;text-align:center}.sg-page-title[style="background: white;"]{padding:40px 20px 30px}.sg-page-title h2{font-weight:300}
.sg-item-card{display:-webkit-flex;display:flex}.sg-item-card .sg-image-wrapper{-webkit-flex-basis:35%;flex-basis:35%;padding-bottom:35%;position:relative;width:35%;background-color:#ccc;height:100%;padding:0}.sg-item-card .sg-image-wrapper img{height:100%;left:0;object-fit:cover;position:absolute;top:0;width:100%}.sg-item-card .sg-copy-wrapper{background:#fff;display:-webkit-flex;display:flex;-webkit-flex-basis:65%;flex-basis:65%;-webkit-flex-flow:row wrap;flex-flow:row wrap;padding:16px 14px;width:65%;-webkit-justify-content:space-between;justify-content:space-between}.sg-item-card .sg-copy-wrapper .sg-title{-webkit-flex-basis:70%;flex-basis:70%;width:70%}.sg-item-card .sg-copy-wrapper .sg-price{-webkit-flex-basis:20%;flex-basis:20%;text-align:right;width:20%;display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;-webkit-align-items:flex-start;align-items:flex-start}.sg-item-card .sg-copy-wrapper .sg-button-wrapper{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;-webkit-align-self:flex-end;align-self:flex-end;-webkit-align-items:flex-end;align-items:flex-end}.sg-item-card .sg-copy-wrapper a,.sg-item-card .sg-copy-wrapper button{-webkit-align-self:flex-end;align-self:flex-end;text-overflow:ellipsis;max-width:100%;min-width:0;white-space:nowrap;overflow:hidden;display:inline-block;padding-left:8px;padding-right:8px;-webkit-flex-shrink:1;flex-shrink:1}.sg-item-card .sg-copy-wrapper a>span,.sg-item-card .sg-copy-wrapper button>span{display:contents}.sg-item-card .sg-copy-wrapper a+a,.sg-item-card .sg-copy-wrapper a+button,.sg-item-card .sg-copy-wrapper button+a,.sg-item-card .sg-copy-wrapper button+button{margin-left:8px}
.sg-tooltips{overflow:hidden;white-space:nowrap;height:40px;-webkit-flex:2 1 35%;flex:2 1 35%}.sg-tooltips .sg-tooltip{background:#fff;border-radius:0;font-size:14px}.sg-tooltips .sg-tooltip:hover,.sg-tooltips .sg-tooltip:focus,.sg-tooltips .sg-tooltip:active{background:#fff}
.sg-hero{display:-webkit-flex;display:flex;margin-bottom:18px;min-height:300px}.sg-hero .sg-image-wrapper{-webkit-flex-basis:50%;flex-basis:50%;position:relative;width:50%;min-height:inherit}.sg-hero .sg-image-wrapper img{height:100%;left:0;object-fit:cover;position:absolute;top:0;width:100%;object-position:center}.sg-hero .sg-copy-wrapper{background:#fff;-webkit-flex-basis:50%;flex-basis:50%;padding:28px 14px 16px 24px;width:50%;display:-webkit-flex;display:flex;-webkit-flex-flow:column wrap;flex-flow:column wrap;position:relative;min-height:inherit}.sg-hero .sg-button-wrapper{display:-webkit-flex;display:flex;width:100%;-webkit-justify-content:flex-end;justify-content:flex-end;margin-top:48px}.sg-hero .sg-button-wrapper a,.sg-hero .sg-button-wrapper button{-webkit-align-self:flex-end;align-self:flex-end}.sg-hero .sg-price-wrapper{position:static;margin-top:28px;text-align:left}.sg-hero .sg-price-wrapper *{font-weight:300}@media only screen and (max-width: 768px){.sg-hero{-webkit-flex-flow:column wrap;flex-flow:column wrap;min-height:auto}.sg-hero .sg-image-wrapper{padding-bottom:56.25%}.sg-hero .sg-image-wrapper,.sg-hero .sg-copy-wrapper{width:100%;-webkit-flex-basis:100%;flex-basis:100%}.sg-hero .sg-price-wrapper{position:absolute;top:0;right:14px;text-align:right}}
.sg-operator-markdown h1,.sg-operator-markdown h2,.sg-operator-markdown h3{font-weight:400;color:rgba(0,0,0,.87);font-size:16px;line-height:28px;margin-bottom:6px}.sg-operator-markdown h4,.sg-operator-markdown h5,.sg-operator-markdown h6,.sg-operator-markdown li,.sg-operator-markdown span,.sg-operator-markdown p,.sg-operator-markdown a,.sg-operator-markdown table{color:#9e9e9e;font-size:14px;line-height:22px;font-weight:300;margin-bottom:4px}.sg-operator-markdown table{margin:6px 0}.sg-operator-markdown th,.sg-operator-markdown td{padding-right:6px}.sg-operator-markdown em{font-style:italic}.sg-operator-markdown strong{font-weight:bold}.sg-operator-markdown a{text-decoration:underline}
.sg-site-info{display:-webkit-flex;display:flex;background:#fff}.sg-site-info .sg-column{-webkit-flex-basis:50%;flex-basis:50%;width:50%;padding:28px 14px 28px 24px}.sg-site-info .sg-column.full-bleed{padding:0}.sg-site-info .sg-map{position:relative;min-height:inherit}.sg-site-info .sg-map img{position:absolute;width:100%;height:100%;top:0;left:0;object-fit:cover;object-position:center}@media only screen and (max-width: 768px){.sg-site-info{-webkit-flex-flow:column wrap;flex-flow:column wrap}.sg-site-info .sg-column{-webkit-flex-basis:100%;flex-basis:100%;width:100%}}@media only screen and (max-width: 400px){.sg-site-info .sg-column:first-of-type{display:none}}
.sg-unit-info{display:-webkit-flex;display:flex;background:#fff}.sg-unit-info .sg-column{-webkit-flex-basis:50%;flex-basis:50%;width:50%;padding:28px 14px 28px 24px}.sg-unit-info .sg-column.full-bleed{padding:0}@media only screen and (max-width: 768px){.sg-unit-info{-webkit-flex-flow:column wrap;flex-flow:column wrap}.sg-unit-info .sg-column{-webkit-flex-basis:100%;flex-basis:100%;width:100%}}
.react-datepicker {
  border-radius: 0;
}

.react-datepicker__header {
  _background: #7e45a4;
  _color: white;
  border-radius: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__day-name {
  _color: #fff;
}
.sg-noresults{text-align:center;padding-top:24px}.sg-noresults .sg-noresults-icon{font-size:100px}

