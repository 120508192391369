.react-datepicker {
  border-radius: 0;
}

.react-datepicker__header {
  _background: #7e45a4;
  _color: white;
  border-radius: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__day-name {
  _color: #fff;
}