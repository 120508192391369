/*
  Variables
*/

// Gutters, padding + margins
$size--gutter-default: 18px;
$size--gutter-medium: 14px;
$size--gutter-small: 12px;

/* 
  Reset
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
  Material Icons
*/
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*
  Google Fonts
*/
@import url('https://fonts.googleapis.com/css?family=Gothic+A1:300,400,500,700');

/*
  Global styles
*/
*, *:before, *:after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

#root {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}

body {
  font-family: 'Gothic A1', sans-serif;
  overflow-x: hidden;
  background-color: #f2f2f2;
}

img {
  width: 100%;
  height: auto;
  display: block; // TODO remove
}

u { cursor: pointer; }

a {
  text-decoration: none;
}

i[class*="fa"] {
  line-height: 1.2;
}

.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.divider {
  width: 100%;
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, .4);
}

input:disabled { pointer-events: none !important; }

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}