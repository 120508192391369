.sg-site-info {
  display: flex;
  background: white;

  .sg-column {
    flex-basis: 50%;
    width: 50%;
    padding: 28px 14px 28px 24px;

    &.full-bleed { padding: 0; }
  }  

  .sg-map {
    position: relative;
    min-height: inherit;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sg-site-info {
    flex-flow: column wrap;

    .sg-column {
      flex-basis: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .sg-site-info {
    .sg-column {
      &:first-of-type { display: none; }
    }
  }
}
