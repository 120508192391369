.sg-operator-markdown {
  h1, h2, h3 {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 6px;
  }

  h4, h5, h6, li, span, p, a, table {
    color: rgb(158, 158, 158);
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 4px;
  }

  table { margin: 6px 0; }

  th, td { padding-right: 6px; }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }
}
