.sg-hero {
  display: flex;
  margin-bottom: 18px;
  min-height: 300px;
  
  .sg-image-wrapper {
    flex-basis: 50%;
    position: relative;
    width: 50%;
    min-height: inherit;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      object-position: center;
    }
  }

  .sg-copy-wrapper {
    background: white;
    flex-basis: 50%;
    padding: 28px 14px 16px 24px;
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    position: relative;
    min-height: inherit;
  }

  .sg-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 48px;

    a,
    button { align-self: flex-end; }
  }

  .sg-price-wrapper {
    position: static;
    margin-top: 28px;
    text-align: left;

    * { font-weight: 300; }
  }
}

@media only screen and (max-width: 768px) {
  .sg-hero {
    flex-flow: column wrap;
    min-height: auto;

    .sg-image-wrapper { padding-bottom: 56.25%; }

    .sg-image-wrapper,
    .sg-copy-wrapper {
      width: 100%;
      flex-basis: 100%;
    }

    .sg-price-wrapper {
      position: absolute;
      top: 0;
      right: 14px;
      text-align: right;
    }
  }
}
